/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

:root {
  --menu-bg: #0e1f33;
  --shadow-color: rgba(8, 21, 66, 0.04);
  --border: #f0f2fd;
  --white: #fff;
  --color-leftmenu: #868f9a;
  --text-color: #4e525e;
  --font-main: "Outfit", sans-serif;
  --color-blue: #007aff;
  --color-green: #60b057;
  --color-red: #fc6860;
}

/* ------Scrollbar CSS Start----- */
/* Firefox */
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgb(187, 187, 187);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-blue);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-blue);
}

/* hide arrow button on number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ------Scrollbar CSS End----- */

* {
  font-family: var(--font-main);
  /* transition: 0.5s; */
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-main) !important;
  margin-top: 0 !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  color: var(--bs-heading-color);
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.app-sidebar {
  position: fixed;
  inset-block: 0;
  inset-inline-start: 0;
  width: 150px;
  background: var(--menu-bg);
  overflow: auto;
  box-shadow: 0 1.563rem 3.125rem var(--shadow-color);
  transition: all 100ms ease;
  z-index: 999;
  font-family: var(--font-main);
}
.modal-body .ngx-select .ngx-dropdown-container .ngx-dropdown-list-container {
  position: absolute !important;
}
.app-sidebar__header {
  display: flex;
  align-items: center;
  height: 4.125rem;
  width: 150px;
  background: var(--menu-bg);
  position: fixed;
  z-index: 999;
  inset-block-start: 0;
  inset-inline: 0;
  transition: all 100ms ease;
}

.btn.btn-loading {
  pointer-events: none;
  opacity: 0.6;
}

.btn.btn-loading:before {
  content: "loading...";
}

.btn.btn-loading span {
  display: none !important;
}

.form-layout.default-permission .rdiobox input[type="radio"][disabled] + span,
.form-layout.default-permission
  .rdiobox
  input[type="radio"][disabled]
  + span:before,
.form-layout.default-permission
  .rdiobox
  input[type="radio"][disabled]
  + span:after,
.employee-view-form-section .rdiobox input[type="radio"][disabled] + span,
.employee-view-form-section
  .rdiobox
  input[type="radio"][disabled]
  + span:before,
.employee-view-form-section
  .rdiobox
  input[type="radio"][disabled]
  + span:after {
  opacity: 1 !important;
}

.employee-view-form-section input {
  background-color: transparent !important;
}

.employee-view-form-section .rdiobox {
  display: inline-block;
  margin-right: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.detail-box,
.employee-view-form-section,
.employee-edit-form-section,
.employee-add-form-section {
  padding: 20px;
  border: 1px solid #e9e9e9;
  color: #222;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.21);
  border-radius: 3px;
  background-color: #fff;
}

.main-logo {
  text-align: center;
  margin: 0 auto !important;
  padding: 0.625rem 1.25rem;
}

.employee-view-form-section h5,
.employee-edit-form-section h5,
.employee-add-form-section h5 {
  font-size: 16px;
}

.main-logo img {
  margin: 0 auto;
  max-width: 100%;
}

.main-sidemenu {
  margin-block-start: 66px;
  padding: 15px 18px;
}

.main-sidemenu ul {
  margin: 0;
  padding: 0;
}

.main-sidemenu ul li {
  margin: 0;
  padding: 0 0 12px;
}

.main-sidemenu ul li span {
  text-align: center;
  display: block;
  padding: 15px 13px;
  cursor: pointer;
  background: transparent;
  border-radius: 8px;
}

.main-sidemenu ul li span:hover {
  background: #162e4a52;
}

.main-sidemenu ul li span img {
  margin: 0 auto 10px auto;
  transition: 0s;
}

.main-sidemenu ul li span h4 {
  color: var(--color-leftmenu);
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: 400;
  margin-bottom: 0;
}

.main-sidemenu ul li span:hover img,
.main-sidemenu ul li.active span img {
  filter: brightness(0) saturate(100%) invert(39%) sepia(82%) saturate(4206%)
    hue-rotate(198deg) brightness(99%) contrast(111%);
}

.main-sidemenu ul li span:hover h4,
.main-sidemenu ul li.active span h4 {
  color: var(--color-blue);
}

/* left bar end  */

.app-content {
  min-height: 100vh;
  background: #f5f6fa;
  padding-inline-start: 150px;
}

.app-header {
  padding: 15px;
  background: var(--white);
  box-shadow: var(--shadow-color);
  height: 66px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f5f6fa;
}

.app-header h3 {
  margin-bottom: 0;
  font-size: 16px;
  color: var(--text-color);
  font-weight: 500;
  line-height: normal;
}

.topfinance-nav ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: auto;
  row-gap: 5px;
}

/* .topfinance-nav ul li:first-child{
    margin-left: 0;
} */
/* .topfinance-nav ul li:last-child{
    margin-right: 0;
} */
.topfinance-nav ul li {
  margin-right: 30px;
}

.topfinance-nav ul li.active,
.topfinance-nav ul li.active a {
  color: #007bff !important;
}

.topfinance-nav ul li,
.topfinance-nav ul li a {
  font-size: 13px;
  color: var(--text-color) !important;
  font-weight: 500;
  cursor: pointer;
}

.topfinance-nav ul li.active a {
  color: var(--color-blue) !important;
}

.info-fluid {
  padding: 15px;
}

.info_box .card-header {
  padding: 15px 15px;
  background: var(--white);
  border-color: #efefef !important;
  display: flex;
  align-items: center;
  height: 54px;
  flex-wrap: wrap;
  row-gap: 15px;
}

.info_box {
  border-radius: 4px !important;
  border: solid 1px #efefef !important;
  height: 100%;
}

.cs_select {
  width: 100px;
  position: relative;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 4px;
  font-size: 12px;
  height: 40px;
  border-color: var(--color-blue);
  color: var(--color-blue);
  font-weight: 500;
  background-color: #ffffff;
  background-image: url(assets/images/download.png);
  background-repeat: no-repeat;
  background-size: 11px;
  padding-left: 10px;
  padding-right: 25px;
}

.cs_select:focus {
  border-color: var(--color-blue);
}

.cs_dark {
  color: var(--text-color);
  border-color: #e7eaf1;
  background-image: url(assets/images/download-dark.png);
  background-color: transparent;
}

.form-group {
  position: relative;
  margin-bottom: 28px !important;
}

.mb-0.form-group {
  margin-bottom: 0 !important;
}

.me-13 {
  margin-right: 13px !important;
}

.form-group label {
  color: #6f7880 !important;
  position: absolute;
  top: -8px;
  left: 12px;
  z-index: 1;
  background: #fff;
  padding: 0 5px;
  font-size: 11px;
  margin-bottom: 6px;
  color: #8a8e9c;
  border-radius: 4px;
  line-height: normal;
}

/* comon button */
.button-solide {
  background-color: var(--color-blue) !important;
  color: var(--white) !important;
  font-size: 12px;
  height: 40px !important;
  line-height: 0;
  border-radius: 4px;
  box-shadow: unset !important;
  border: 0;
  flex-shrink: 0;
  width: auto;
  padding: 0 18px;
  min-width: 100px;
  transition: 0.5s;
}

.button-solide:hover {
  background-color: #0863c5 !important;
  color: var(--white) !important;
}

.button-approve {
  background-color: var(--color-green) !important;
}

.button-approve:hover {
  background-color: #33772c !important;
}

.gray-btn {
  background: #e4e6ec !important;
  color: var(--color-blue) !important;
}

.btn-lightblue,
.btn-lightblue:hover {
  background-color: #e6f2ff !important;
  color: #007aff !important;
}

.btn-icon {
  position: relative;
  padding-right: 50px;
}

.btn-icon .bi-caret-right-fill {
  position: absolute;
  width: 32px;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: solid 1px #2d91ff;
}

.btn-border {
  border: solid 1px var(--color-blue);
  background-color: transparent !important;
  color: var(--color-blue) !important;
}

.btn-border:hover {
  background-color: var(--color-blue) !important;
  color: var(--white) !important;
}

.card-body {
  padding: 15px;
}

.p-15 {
  padding: 15px;
}

.serch-main {
  position: relative;
  width: 294px;
}

.form-control:focus {
  border-color: var(--color-blue);
}

.serch-main .form-control {
  box-shadow: unset !important;
  padding-left: 35px;
  font-size: 13px;
  height: 40px;
  width: auto;
  width: 100%;
}

.serch-main i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #8a8e9c;
}

/* atandence panel start */

.att_details {
  background-color: var(--white);
  border: solid 1px #e7eaf1;
}

.att_heder {
  background: #1e2538;
  padding: 15px;
  color: var(--white);
}

.att_heder i {
  color: var(--white);
}

.att_img {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
}

.att_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.att_dtls {
  padding: 5px 12px 5px 15px;
}

.att_dtls h3 {
  font-size: 13px;
  margin-bottom: 4px;
}

.att_dtls p {
  margin-bottom: 0;
  font-size: 12px;
  color: #8a8e9c;
}

.att_date {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
}

.att_date i {
  color: var(--color-blue);
  margin-right: 10px;
}

.border-buttom {
  border-bottom: solid 1px var(--border);
}

.shift-top figure {
  margin-bottom: 0;
  flex-shrink: 0;
  padding-right: 15px;
}

.shift-top figure img {
  width: 28px;
  height: 28px;
}

.shift-top {
  color: #6f7880;
  font-size: 14px;
}

.shift-top strong {
  font-weight: 600;
  margin-right: 8px;
}

.graybg {
  padding: 5px 10px;
  margin: 5px 4px;
  font-size: 13px;
  background: #eeeeee;
  color: #4e525e;
  font-weight: 500;
}

.bg_lightblue {
  background-color: #e6f2ff !important;
  color: var(--color-blue) !important;
}

.bg_lightgreen {
  background-color: #f0f7ef !important;
  color: var(--color-green) !important;
}

.bg_lightred {
  background-color: #ffefef !important;
  color: #fc6860 !important;
}

.bg_lightyello {
  background-color: #fff6e5 !important;
  color: #ffac10 !important;
}

.bg_lightpurpal {
  background-color: #f6efff !important;
  color: #9c5bff !important;
}

.main-dor h3 {
  font-size: 14px;
  color: var(--menu-bg);
  margin-bottom: 10px;
}

.main-dor ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}

.main-dor ul li {
  width: 50%;
  padding: 8px 0;
  font-size: 13px;
}

.main-dor ul li i {
  margin-right: 8px;
}

.main-dor ul li .bi-arrow-down-left {
  color: var(--color-green);
}

.main-dor ul li .bi-arrow-up-right {
  color: var(--color-red);
}

.att_sumary .bg_lightblue {
  font-size: 14px;
  border: 0 !important;
}

.att_sumary .bg_lightblue:hover {
  color: var(--color-blue);
}

/* atandence panel end */

.table_main {
  overflow-x: auto;
  /* margin-left: 234px; */
  overflow-y: auto;
  padding: 0;
}

.headcol {
  width: 150px;
  position: sticky;
  left: 0;
  background-color: #f5f6fa;
  padding: 10px 15px;
  z-index: 3;
  border-left: 1px solid #dee2e6;
  height: 100% !important;
}

.sticky-cell::after,
.headcol::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  right: -1px;
  top: 0;
  background-color: transparent;
  border-right: solid 1px #dee2e6;
}

.sticky-cell::before,
.headcol::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  left: -1px;
  top: 0;
  background-color: transparent;
  border-right: solid 1px #dee2e6;
}

.headcol:first-child {
  /* margin-top: -2px; */
}

.table tr td {
  white-space: nowrap;
  height: 55px;
  vertical-align: middle;
  padding: 10px 15px;
  font-size: 12px;
  text-align: center;
  color: #8a8e9c;
}

.table tr th {
  font-weight: 500;
  font-size: 13px;
  vertical-align: middle;
}

.table thead tr:first-child td {
  /* height: 65px; */
  color: #4e525e;
  font-weight: 600;
  font-size: 13px;
}

.table_main .table tr:first-child td:first-child {
  background: #f5f6fa;
}

.user_dtls figure {
  width: 30px;
  height: 30px;
  margin-bottom: 0;
}

.user_dtls figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: solid 1px #005495;
}

.user_dtls figcaption {
  padding-left: 12px !important;
  text-align: left;
}

.user_dtls figcaption h3 {
  font-size: 13px;
  margin-bottom: 4px;
  color: #333;
}

.user_dtls figcaption h5 {
  font-size: 10px;
  margin-bottom: 0;
  color: #8a8e9c;
  font-weight: 400;
}

/* The container */
.ms_container {
  display: block;
  position: relative;
  padding-left: 25px;
  padding-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 16px;
  height: 16px;
}

/* Hide the browser's default checkbox */
.ms_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: solid 1px #d7d3d3;
  padding: 4px;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.ms_container:hover input ~ .checkmark {
  background-color: #007aff;
}

/* When the checkbox is checked, add a blue background */
.ms_container input:checked ~ .checkmark {
  background-color: #007aff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ms_container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.ms_container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.listing_console_table.table tr:first-child td:first-child {
  height: 121px;
  z-index: 9;
}

.listing_console_table.table tr:first-child td {
  color: #8a8e9c;
}

.listing_console_table.table tr:first-child td span {
  font-size: 14px;
  color: #4e525e;
}

.date-title {
  font-size: 12px !important;
  color: #8a8e9c !important;
  text-align: center !important;
  font-weight: 400 !important;
  line-height: 1.3;
}

.date-title span {
  font-size: 14px !important;
  color: #333 !important;
  font-weight: 600 !important;
}

.employee_attendance_table .table tr:first-child td:first-child {
  height: 71px;
  /* z-index: 9; */
}

.employee_attendance_table .table .headcol {
  width: 300px !important;
}

.employee_attendance_table.table_main {
  margin-left: 299px;
}

.play-box i {
  width: 24px;
  height: 24px;
  border: solid 1px #4182eb;
  color: #4182eb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 14px;
}

.blueborder i {
  border: solid 1px #4182eb !important;
  color: #4182eb !important;
}

.play-box.ms-auto {
  position: relative;
}

.play-box.ms-auto::before {
  content: "";
  width: 1px;
  height: 53px;
  position: absolute;
  left: -15px;
  top: -15px;
  bottom: -15px;
  border-left: solid 1px #dee2e6;
}

.present {
  width: 46px;
  height: 46px;
  background: #f0f7ef;
  color: #60b057;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500 !important;
  padding: 1px;
  margin-bottom: 2px;
}

.present:last-child {
  margin-bottom: 0;
}

.pcus_4 {
  padding: 4px !important;
}

.bg_blue {
  background-color: #e6f2ff;
  color: #007aff;
}

.half_sun {
  background-image: url(assets/images/sun-icon.png);
  background-repeat: no-repeat;
  background-size: 11px;
}

.half_sun {
  background-image: url(assets/images/sun-icon.png);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 30px 28px;
}

.bg_yello {
  background-color: #fff6e5 !important;
  color: #ffac10 !important;
}

.bg_red {
  background-color: #ffefef !important;
  color: #fc6860 !important;
}

/* pay slip */
.in-active {
  background-color: #d5d9e2 !important;
  color: var(--color-blue) !important;
}

.in-active.btn-icon .bi-caret-right-fill {
  border-color: #d5d9e2;
}

.table-left .table tr td {
  text-align: left;
}

.graybtn {
  border: solid 1px #e7eaf1;
  background-color: #e7eaf1;
  font-size: 11px;
  padding: 4px 10px;
  border-radius: 2px;
  display: inline-block;
}

.graybtn:hover {
  border: solid 1px #e7eaf1;
  background-color: #e7eaf1;
}

.approvedbutton:hover {
  border-color: #60b057;
}

.approvedbutton {
  border: solid 1px var(--color-green);
  background-color: #f0f7ef;
  color: var(--color-green) !important;
  display: inline-block !important;
  cursor: unset !important;
}

.yellobtn {
  background-color: #fff6e5 !important;
  border: solid 1px #ffac10 !important;
  color: #ffac10 !important;
}

.cs-w {
  width: 300px;
}

.gcolor {
  color: #60b057;
}

.text-dark {
  color: #4e525e;
}

.button_action {
  width: 33px;
  height: 33px;
  border: solid 1px #60b057 !important;
  color: #60b057 !important;
  background-color: #f0f7ef;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  margin: 4px;
}

.blue_action {
  background-color: #e6f2ff !important;
  color: #007aff !important;
  border-color: #007aff !important;
}

.red_action {
  background-color: #ffefef !important;
  color: #fc6860 !important;
  border-color: #fc6860 !important;
}

.yello_action {
  background-color: #fff6e5 !important;
  border: solid 1px #ffac10 !important;
  color: #ffac10 !important;
}

.shift_name span {
  color: #007aff;
  font-weight: 500;
}

.shift_name button.editic,
.shift_name button.closeic {
  padding: 0;
  border: 0;
  background: transparent;
}

.shift_name button.editic i {
  color: #007aff;
}

.shift_name button.closeic i {
  color: #fc6860;
}

.shift_name button.btn {
  min-width: auto !important;
  height: 35px;
  line-height: 35px;
  padding: 0 15px;
}

.smhover_table .headcol {
  min-width: 320px;
}

/* .smhover_table.table_main{
        margin-left: 349px;
    } */

.smhover_table .table tr:first-child td:first-child {
  background-color: var(--white);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-bg-state: #f5f6fa;
}

.edit-file {
  padding: 0 !important;
}

.edit-file input {
  margin: 0;
  font-size: 13px;
  height: 54px;
  border-radius: 0;
  box-shadow: unset !important;
  border: solid 1px #fff;
  padding: 10px 15px;
  color: #8a8e9c;
}

.edit-file input:focus {
  border: solid 1px #007aff;
}

/* modaldesign start */

.boxshodo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e7eaf1;
  border-radius: 4px;
}

.bg-light {
  background-color: var(--white) !important;
}

.btn-close i::before {
  vertical-align: 0;
  opacity: 0;
}

.btn-close:focus {
  box-shadow: none;
}

.boxshodo h3 {
  font-size: 15px;
  color: var(--text-color);
}

.left-line {
  position: relative;
}

.left-line::after {
  content: "";
  left: -15px;
  position: absolute;
  width: 3px;
  height: 16px;
  background: #007aff;
  transform: translateY(-50%);
  top: 50%;
  border-radius: 0 5px 5px 0;
}

.form-control,
input,
textarea {
  font-size: 12px;
  height: 40px;
  border-radius: 4px;
  font-weight: 500;
}

.modal-body .form-group label {
  color: #4e525e !important;
}

.modal-body .cs_select {
  height: 45px;
}

.amount span {
  position: absolute;
  right: 10px;
  top: 10px;
}

/* modaldesign end */
/* switch button start */
.swicharbtn {
  font-size: 12px;
  color: #8a8e9c;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.buttongray {
  background-color: #e7eaf1 !important;
  color: #4e525e !important;
}

/* switch button end */

.shift-allowance.table tr:first-child td {
  height: 50px;
}

.shift-allowance.table .user_dtls figcaption h3 {
  color: var(--color-blue);
}

.bg-gay .shift-allowance.table tr:first-child td {
  background: #f5f6fa;
}

/* Attendance Modal Style */
.textarea-d {
  font-size: 12px;
  height: 40px;
  border-radius: 4px;
  font-weight: 500;
  padding: 10px;
}

.textarea-d {
  height: 100px !important;
}
.closebtnmd {
  padding: 8px 10px;
  margin-left: 10px;
  flex-shrink: 0;
}

.modal-header {
  padding: 12px 15px;
}

.mat-dialog-title {
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  color: #4e525e;
}

.logtime {
  border: solid 1px #007aff;
  color: #007aff;
  font-size: 14px;
  padding: 6px 15px;
  flex-shrink: 0;
}

.logtime:hover {
  background-color: #007aff;
  color: #fff;
}

.line-righr {
  position: relative;
}

.line-righr::after {
  content: "";
  width: 3px;
  height: 23px;
  background: #007aff;
  border-radius: 0 4px 4px 0;
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
}

.user-details {
  background: #1e2538;
  padding: 15px 20px;
  display: flex;
}

.user-details figure {
  margin-bottom: 0;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  flex-shrink: 0;
}

.user-details figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.usertext {
  width: 100%;
  padding-left: 15px;
}

.usertext h3 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 13px;
}

.usertext ul {
  display: flex;
}

.usertext ul li {
  width: 50%;
}

.usertext ul li:first-child {
  border-right: solid 1px #fff;
  padding-right: 15px;
}

.usertext ul li:nth-child(2) {
  padding-left: 15px;
}

.usertext ul li p {
  color: #c7c8ce;
  margin-bottom: 4px;
}

.logdetails {
  text-align: center;
  padding: 30px 15px;
}

.logdetails h3 {
  font-size: 30px;
  font-weight: 700 !important;
  color: #474846;
  margin-bottom: 4px;
  line-height: 1 !important;
}

.logdetails p {
  color: #6f7880;
  font-size: 14px;
  margin-bottom: 0;
}

.att_details {
  /* width: 400px; */
  /* position: absolute; */
  /* right: 0; */
  bottom: 0;
  height: 100%;
  overflow-y: auto;
}

.attbtn {
  position: relative;
  color: #007aff;
  padding-left: 20px;
  font-size: 14px;
}

.attbtn i {
  position: absolute;
  transform: rotate(40deg);
  left: 0;
}

.editbtn {
  font-size: 14px;
  color: #007aff;
}

/* Radio Box Start*/
.radio_container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio_container span {
  font-weight: 500;
  font-size: 13px;
  color: #4e525e;
}

.radio_container span input {
  height: auto;
}

/* Radio Box End*/

.mat-expansion-panel-spacing {
  margin: 0 !important;
}

.mat-expansion-panel .mat-expansion-panel-header {
  padding: 0 0px;
}

.mat-expansion-indicator {
  position: absolute;
  left: 20px;
  width: 25px;
  height: 25px;
  border: solid 1px #f2f3f5;
  text-align: center;
  background: #f2f3f5;
  font-size: 12px;
  border-radius: 5px;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: unset;
  border-bottom: solid 1px #e7eaf1;
}

.heading_div {
  padding: 0 0px 0 48px !important;
  font-size: 13px;
  border-bottom: solid 1px #e7eaf1;
  background-color: #f5f6fa;
}

.heading_div div[class^="col-"] {
  padding: 14px 15px;
  font-weight: 500;
  border-right: solid 1px #e7eaf1;
}

.mat-expansion-panel-header.mat-expanded {
  height: auto;
}

.detailstitle {
  padding: 0 0px 0 48px !important;
  font-size: 13px;
  border-bottom: solid 1px #e8ebf1;
}

.detailstitle div[class^="col-"] {
  padding: 14px 15px;
  border-right: solid 1px #e7eaf1;
  color: #8a8e9c;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.detailstitle .btn {
  border: 0;
  /* padding: 0 !important; */
  font-size: 13px;
}

.ckdiv {
  position: relative;
}

.ckdiv .ms_container {
  position: absolute;
  left: -35px;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-expansion-panel-body p {
  margin: 0;
}

.btn-small {
  padding: 0 6px;
  border: 0;
  font-size: 13px;
}

.minheights {
  max-height: 400px;
  overflow-y: auto;
}

/* width */
.minheights::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

/* Track */
.minheights::-webkit-scrollbar-track {
  background-color: rgb(187, 187, 187);
}

/* Handle */
.minheights::-webkit-scrollbar-thumb {
  background: var(--color-blue);
  border-radius: 10px;
}

/* Handle on hover */
.minheights::-webkit-scrollbar-thumb:hover {
  background: var(--color-blue);
}

.mat-expanded .mat-expansion-indicator {
  background-color: #007aff;
}

.mat-expanded .mat-expansion-indicator::after {
  color: rgb(255 255 255 / 100%);
}

/* bd style 24-04-2024 Start*/

.allocshift {
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  position: relative;
}

.allocshift i {
  color: #ff8001;
  font-size: 20px;
  margin-right: 6px;
}

.allocshift img {
  width: 18px;
  margin-right: 6px;
}

.tooltipbd {
  position: absolute;
  top: -40px;
  left: -42px;
  background: #fff;
  color: #000;
  padding: 6px 10px;
  border-radius: 5px;
  transition: 0.5s;
  opacity: 0;
  transform: scale(0);
  -webkit-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  -moz-transform: scale(0);
}

.tooltipbd:after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
}

.allocshift:hover .tooltipbd {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
}

.logdetails h3 i {
  color: #007aff;
  margin-right: 10px;
}

.logtimes .form-group .cs_select {
  border-color: #dee2e6;
  color: black;
}

.tooltipbd1 ul li p {
  font-size: 13px;
  margin: 0;
  color: #000;
}

.tooltipbd1 ul li {
  padding: 0 !important;
}

.tooltipbd1 {
  width: 210px;
  top: -62px;
  left: -95px;
}

.tooltipbd1 ul li p:nth-child(1) {
  color: #007aff;
}

.usertext ul li + li {
  border-left: 1px solid #ccc;
  padding-left: 10px !important;
}

.formgroupsec {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.formgroupseclft {
  flex: 0 0 75%;
  max-width: 75%;
  z-index: 1;
}

.formgroupsecrgt {
  border: 1px solid #e7eaf1;
  border-left: 0;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 0 0 40px;
  position: relative;
}

.reguhours span {
  color: #16295e;
  font-size: 20px;
  font-weight: 600;
  margin-right: -30px;
  background: #fff;
}

.formgroupsecrgt:after,
.formgroupsecrgt:before {
  position: absolute;
  content: "";
  left: -5px;
  width: 10px;
  height: 10px;
  border: 1px solid #e7e7e8;
  border-radius: 50px;
  background: #fff;
}

.formgroupsecrgt:before {
  top: -5px;
}

.formgroupsecrgt:after {
  bottom: -5px;
}

.p-20 {
  padding: 20px;
}

.regularizepopup2 {
  background: #fff;
  border: 1px solid #e7eaf1;
}

.regularisetable.table tr:first-child td:first-child {
  background: transparent;
}

.regularisetable.table tr td {
  text-align: left;
  height: 50px;
}

.regularisetable thead tr th {
  background: #fcfcfc;
  height: 50px;
  vertical-align: middle;
  color: #4e525e;
  font-size: 14px;
  font-weight: 600;
}

.regularisetable thead tr {
  border-top: 1px solid #e7eaf1;
}

.regularisetable.table tr td {
  color: #8a8e9c;
  font-size: 14px;
  font-weight: 400;
}

.inoutpunch {
  position: relative;
  margin-left: auto;
}

.inoutpunch .tooltipbd {
  box-shadow: 0px 2px 8px #00000029;
  border: 1px solid #e4e4e4;
  top: -40px;
  left: -5px;
  z-index: 99;
}

.inoutpunch:hover .tooltipbd {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  -moz-transform: scale(1);
}

/* bd style 24-04-2024 End*/

.filter_btn button.adv_btn {
  border: 0;
  background-color: transparent;
  height: 40px;
  font-size: 13px;
}

.filter_btn button.adv_btn:hover {
  background: #f5f6fa !important;
  color: var(--color-blue) !important;
  border-radius: 5px;
}

.dropdowncs {
  background-color: #fff;
  padding: 25px !important;
  width: 350px !important;
  position: absolute;
  z-index: 9;
  box-shadow: 1px 2px 5px 0px #00000063;
  right: 0;
  top: 40px;
}

.dropdowncs .closed_btn {
  position: absolute;
  right: 2px;
  top: 2px;
  background-color: transparent;
  border: 0 !important;
}

.dropdowncs .closed_btn:hover {
  color: var(--color-blue);
}

.dropdowncs .mat-mdc-menu-content {
  padding: 0 !important;
}

.dropdowncs h3 {
  font-size: 15px;
  color: #687077;
  margin-bottom: 5px;
}

.customfild {
  position: relative;
}

.customfild i {
  position: absolute;
  top: 9px;
  right: 10px;
  color: #6f7880;
  font-size: 12px;
}

.gear_btn {
  border: 0;
  background-color: transparent;
  color: #8a8e9c;
}

.morebtn {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  line-height: normal;
  height: 15px;
  background-color: transparent;
  font-size: 13px;
}

.morebtn i {
  margin-left: 5px;
  color: var(--color-blue);
}

.dropdownc .mat-mdc-menu-content {
  border: 0 !important;
  padding: 0 !important;
}

.dropdownc .mat-mdc-menu-content button {
  min-height: 35px;
}

.dropdownc .mat-mdc-menu-content button span {
  font-size: 13px !important;
  letter-spacing: 0 !important;
  font-family: var(--font-main) !important;
}

/* 26.04.24 */

.graycolor {
  color: #a1a2a2;
}

.dropdowncslft {
  right: inherit;
  left: 0;
}

/* Setting */

.bd_settingstep {
  padding: 0 4%;
  max-width: 72%;
  position: relative;
  margin: 15px 0 40px 0;
}

.bd_settingstep .navline {
  height: 2px;
  background: #dae9f9;
  position: absolute;
  width: 75%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15px;
  z-index: 1;
}

.bd_settingstep .stepnav .stepnavlink.compactive  .stround {
  border-color: #009307 !important;
}

.bd_settingstep .stepnav .stepnavlink.compactive i {
  color: #009307 !important;
}


.bd_settingstep .navline_progress {
  height: 2px;
  background: #06d072;
  position: absolute;
  width: 75%;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 15px;
  z-index: 1;
}

.bd_settingstep .stepnav {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
}

.bd_settingstep .stepnav .stepnavlink {
  flex: 1;
  text-align: center;
  margin: 7px 0 0 0;
  cursor: pointer;
}

.bd_settingstep .stepnav .stepnavlink a i {
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  color: #4b4b4b;
  display: block;
  margin: 8px 0 0 0;
}

.bd_settingstep .stepnav .stepnavlink .stround {
  width: 18px;
  height: 18px;
  display: block;
  background: #fff;
  border: 5px solid #6d9fd8;
  border-radius: 50px;
  margin: auto;
}

.bd_settingstep .stepnav .stepnavlink.active .stround {
  border-color: #007aff;
  width: 20px;
  height: 20px;
}

.bd_settingstep .stepnav .stepnavlink.active i {
  color: #007aff;
}

.partnerdetails {
  border-radius: 4px;
}

.idtype {
  border-right: 0 !important;
  display: flex;
}

.idtype p {
  margin-bottom: 0 !important;
}

.idtype p + p {
  border-left: 1px solid #ccc;
  padding-left: 20px;
  margin-left: 20px;
}

.partnerdetails .usertext ul {
  align-items: center;
}

.userimg {
  position: relative;
}

.userimg .editicon {
  position: absolute;
  top: 0;
  right: 0;
  background: #3a476a;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  color: #fff;
  text-align: center;
  line-height: 25px;
  font-size: 10px;
}

.partnerdetails figure.userimg {
  width: 100px;
  height: 100px;
}

.partnerdetails .usertext {
  padding-left: 25px;
}

.partnerlist {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1%;
  margin-right: -1%;
}

.partnerlist .partnerbox {
  flex: 0 0 14.6%;
  max-width: 14.6%;
  border: 1px solid #cfcfcf;
  border-radius: 4px;
  padding: 20px;
  margin-top: 42px;
  margin-bottom: 42px;
  margin-left: 1%;
  margin-right: 1%;
}

.partnerimg {
  width: 85px;
  height: 85px;
  border-radius: 50px;
  overflow: hidden;
  margin: auto;
  margin-top: -63px;
  margin-bottom: 20px;
}

.partnerimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.partnerinfo .text-center p {
  color: #007aff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.partnerinfo .text-center p span {
  color: #8a8e9c;
  font-size: 12px;
  display: block;
  font-weight: 400;
  margin-top: 2px;
}

.partnerinfo ul li + li {
  margin-top: 8px;
}

.partnerinfo ul li {
  color: #4f5051;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
}

.partnerinfo ul li span {
  color: #007aff;
}

/* 30.04.2024 */

.radiolabel {
  font-size: 14px;
  flex: 0 0 100%;
  max-width: 100%;
}

.heading_div.pl-0 {
  padding-left: 0 !important;
}

.roletable thead th {
  background: #f5f6fa;
  height: 50px;
  vertical-align: middle;
  font-weight: 500;
  font-size: 13px;
  color: #212529;
}

.roletable thead {
  border-left: 1px solid #f5f6fa;
  border-right: 1px solid #f5f6fa;
  border-top: 1px solid #f5f6fa;
}

.roletable.table tr:first-child td:first-child {
  background: #fff;
  color: #8a8e9c;
  font-weight: 400;
}

.blueapprovedbutton {
  border: solid 1px #2196f3;
  background-color: #fff;
  color: #2196f3 !important;
  display: inline-block !important;
}

.dropdowncs.p-0 {
  padding: 0 !important;
}

.form-group.mb-2 {
  margin-bottom: 1rem !important;
}

.suggest-text {
  display: flex;
  flex-wrap: wrap;
}

.suggest-text li {
  background: #cde5ff;
  padding: 10px;
  margin: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.w200 {
  width: 200px;
}

.f13 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 18px !important;
}

.w40 {
  width: 40px;
  min-width: auto;
  padding: 0;
}

.underline {
  border-bottom: 1px solid #b9b9b9;
  position: relative;
}

.underline:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1px;
  height: 3px;
  width: 100px;
  background: #f48e39;
}

/* / Attendance Policy Button  / */
.at-btn {
  height: 40px;
  width: 42px;
  margin: 0;
  border: 1px solid #c1c1c1 !important;
  background: transparent !important;
  border-radius: 4px;
}

/* 06.05.2024 */

.combranchbox {
  flex: 0 0 33%;
  max-width: 33%;
  border-right: 1px dashed #b1b1b1;
  border-left: 1px dashed #b1b1b1;
  padding-left: 50px;
  margin-left: 0.3%;
}

.combranch {
  padding: 15px 0;
}

.combranchbox:nth-child(1) {
  margin-left: 0;
  padding-left: 0;
  border-left: 0;
}

.combranchbox:nth-last-child(1) {
  border-right: 0;
}

.combranchbox ul li span {
  width: 50%;
  display: inline-block;
  color: #8a8e9c;
  font-size: 14px;
}

.combranchbox ul li strong {
  width: 50%;
  display: inline-block;
  color: #4e525e;
}

.combranchbox ul li + li {
  margin-top: 15px;
}

.details-emp {
  padding-left: 30px;
  padding-right: 30px;
}

.details-emp h3 {
  color: #fff;
  font-size: 16px;
  margin-bottom: 6px !important;
}

.details-emp ul li a {
  position: relative;
  display: block;
  font-size: 13px;
  color: var(--white);
  padding-left: 20px;
  margin-bottom: 6px;
  font-weight: 300;
}

.details-emp ul li a i {
  position: absolute;
  left: 0;
}

.partnerdetails .usertext {
  padding-left: 25px;
}

.employ-details {
  padding-left: 40px;
}

.employ-details ul li p {
  color: var(--white);
  margin-bottom: 0;
  font-size: 13px;
  line-height: 24px !important;
}

.employ-details ul {
  display: flex;
}

.employ-details ul li {
  display: block;
  padding: 5px 50px;
  text-align: left !important;
}

.employ-details ul li + li {
  border-left: solid 1px #4f5b7b;
}

.employdetails h2 {
  font-size: 15px;
}

.employdetails .form-group label {
  color: #4e525e !important;
}

.file-upload input {
  background-color: #fff;
  position: relative;
  border: 0;
}

.file-upload .form-control::before {
  content: "";
  background: #f2f2f2;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  border: dashed 1px #a8a8a8;
  background-image: url(assets/images/upload.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90px;
}

.file-upload label {
  background: linear-gradient(
    0deg,
    rgba(242, 242, 242, 1) 50%,
    rgba(255, 255, 255, 1) 50%
  );
}

.file-upload p {
  font-size: 12px;
}

.desc-tooltip .bi {
  color: #2196f3;
}

.shortcut-menu ul li {
  padding: 6px 12px;
  margin-right: 12px;
  position: relative;
}

.shortcut-menu ul li::before {
  content: "";
  width: 0;
  height: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--color-blue);
  transition: 0.3s ease-in-out;
}

.shortcut-menu ul li a {
  color: var(--text-color);
  font-weight: 600;
}

.shortcut-menu ul li.active a,
.shortcut-menu ul li:hover a {
  color: var(--color-blue);
}

.shortcut-menu ul li.active::before,
.shortcut-menu ul li:hover::before {
  width: 100%;
}

.vaultbox {
  box-shadow: 0px 0px 20px #ebebeb;
  border: 1px solid #ffffff80;
  border-radius: 10px;
  text-align: center;
  padding: 20px 15px;
  margin: 1%;
  flex: 0 0 9.1%;
  max-width: 9.1%;
}

.vaultbox i {
  color: #007aff;
  font-size: 50px;
  line-height: 50px;
}

.vaultbox h5 {
  margin: 5px 0 0 0 !important;
  color: #3e3e3e;
  font-size: 14px;
  line-height: 20px !important;
}

.innervaultbox i {
  color: #ffac10;
}

.innervaultboxExp i {
  color: #484642;
}

.innervaultbox {
  background: #e6f2ff;
}

.innervaultboxExp {
  background: #e6f2ff;
}

.vaeditbtn {
  text-align: right;
  position: relative;
  top: -10px;
}

.vaeditbtn button.btn {
  padding: 0;
}

.vaeditbtn button.btn i {
  font-size: 18px;
  line-height: 18px;
  color: #878787;
}

.vadropdowncs {
  background: #fff;
  position: absolute;
  top: 100%;
  right: 0;
  width: 100px;
  box-shadow: 0px 0px 30px #0000003b;
}

.vadropdowncs a {
  display: block;
  text-align: center;
  padding: 10px;
  color: #656667;
  font-size: 12px;
}

.vadropdowncs a + a {
  border-top: 1px solid #e7eaf1;
}

.vadropdowncs:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: -10px;
  right: 0px;
}

.uploadfile input#formFile {
  height: 150px;
}

/* End */

/* login start 11-05-2024 */
.ngx-dropdown-container .ngx-dropdown-button {
  height: 40px;
}

.loginmain {
  width: 100%;
  min-height: 100vh;
}

.loginbg {
  background: #0e1f33;
}

.login-bg {
  width: 100%;
  max-width: 600px;
  padding: 30px;
}

.login-bg .login-logo {
  margin-bottom: 20px;
}

.login-bg .login-logo img {
  width: 250px;
}

.login-bg p {
  color: #fff;
  font-weight: 400;
}

.login-form {
  width: 100%;
  max-width: 350px;
}

.login-form h3 {
  margin-bottom: 8px;
  text-align: center;
  font-size: 18px;
}

.login-form p {
  margin-bottom: 20px;
  text-align: center;
}

.userinput .form-group {
  margin-bottom: 0 !important;
  height: 55px;
  align-items: center;
  padding: 8px 10px !important;
}

.userinput {
  margin-bottom: 20px;
}

.userinput .f-label {
  font-size: 13px;
  margin-bottom: 2px;
}

.userinput .userid {
  width: 20px;
  height: 20px;
  background: url(assets/images/user.png) no-repeat;
  background-size: contain;
}

.userinput .form-group .mat-icon {
  font-size: 20px;
}

/* loginend */

.ngx-dropdown-container .ngx-dropdown-button {
  font-size: 12px;
}

.ngx-dropdown-container .ngx-dropdown-button .nsdicon-angle-down:before {
  height: 7px !important;
  width: 7px !important;
  top: 3px !important;
}

.ngx-dropdown-container .ngx-dropdown-list-container .search-container label {
  background-color: transparent;
  font-size: 12px !important;
}

.ngx-dropdown-container .ngx-dropdown-list-container {
}

.ngx-dropdown-container
  .ngx-dropdown-list-container
  .selected-items.selected-items
  .selected-item {
  font-size: 12px !important;
  margin-bottom: 2px;
  margin-top: 5px;
  padding: 5px 10px !important;
  border-radius: 4px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.ngx-dropdown-container
  .ngx-dropdown-list-container
  .selected-items.selected-items
  .selected-item
  .nsdicon-close {
  order: 2;
}

.nsdicon-close {
  width: 22px;
  height: 22px;
  text-align: center;
  font-size: 0px !important;
  border-radius: 50%;
  background: #fff url(assets/images/cross.png) no-repeat center;
  background-size: 10px;
  position: relative;
  right: -4px;
  flex-shrink: 0;
}

.available-items {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.available-items .available-item {
  padding: 3px 0 !important;
  font-size: 12px !important;
}

.selected-items {
  margin: 0 !important;
}

.ngx-dropdown-list-container hr {
  margin: 5px 0 !important;
}

.form-group label.checkbox-div,
.border-check {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  position: relative !important;
  top: 0 !important;
  border: solid 1px #dee2e6;
  border-radius: 4px;
  left: 0 !important;
  padding-left: 10px !important;
  color: #000 !important;
  cursor: pointer;
  height: 40px;
  font-size: 13px;
}

.border-check.form-group label {
  position: unset;
  padding: 0;
  margin: 0;
  margin-right: 10px;
}

.border-check.form-group .ms_container .checkmark {
  position: relative;
}

.form-group label.checkbox-div input {
  margin-right: 5px !important;
}

.filtermain .form-group {
  margin-bottom: 0 !important;
}

input[type="checkbox"] {
  height: auto !important;
}

.actiondropdown {
  font-size: 12px;
}
.disable-btn {
  font-size: 12px;
}

.actiondropdown button {
  font-size: 12px !important;
  border: solid 1px #60b057 !important;
  border-radius: 4px;
  background: #f0f7ef !important;
  color: #60b057 !important;
  padding: 7px 15px !important;
}
.disable-btn button {
  font-size: 12px !important;
  border: solid 1px #ffac10 !important;
  border-radius: 4px;
  background: #fff6e5 !important;
  color: #ffac10 !important;
  padding: 7px 15px !important;
}

.buttonstyle {
  font-size: 12px !important;
  border-radius: 4px;
  border: solid 1px #007aff !important;
  background: #e6f2ff !important;
  color: #007aff !important;
  padding: 9px 12px !important;
}

.bgred {
  border: solid 1px #fc6860 !important;
  background: #ffefef !important;
  color: #fc6860 !important;
}

.actiondropdown .dropdown-content {
  border-radius: 4px;
}
.disable-btn .dropdown-content {
  border-radius: 4px;
}

.actiondropdown .dropdown-content a {
  padding: 6px 12px !important;
  cursor: pointer;
}
.disable-btn .dropdown-content a {
  padding: 6px 12px !important;
  cursor: pointer;
}

.gap-8 {
  row-gap: 8px;
}

.c-table label {
  display: flex;
  align-items: self-start;
  padding: 6px 0;
  font-size: 12px;
  font-weight: 600;
  color: #4e525e;
  line-height: normal;
}

.c-table label input {
  margin-right: 5px;
  position: relative;
  top: 0px;
}

.c-td {
  display: inline-block;
  width: 33.33%;
}

/* login End 11-05-2024 */

/* style 13-05-2024 Start */

.profiledetails {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px;
  background: #1e2538;
  border: solid 1px #707070;
  border-radius: 4px;
}

.profile-details {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
}

.profile-details img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.employ_name {
  color: #fff;
  padding: 0 15px;
  min-width: 300px;
}

.employ_name h4 {
  color: var(--white);
  font-size: 18px;
  margin-bottom: 6px;
}

.employ_name ul li {
  margin-bottom: 5px;
}

.employ_name ul li span {
  color: var(--white);
}

.profile-describe {
  width: 100%;
}

.profile-describe h6 {
  font-size: 14px;
  color: #bec1c9;
  font-weight: 400 !important;
}

.profile-describe span {
  color: #fff;
}

.profile-describe [class*="col-"] {
  border-right: solid 1px #4f5b7b;
  padding-left: 40px;
  padding-right: 40px;
}

.profile-describe [class*="col-"]:last-child {
  border: 0;
}

.topfinance-nav ul li a {
  text-transform: uppercase;
}

.error-element {
  color: red !important;
}

.topfinance-nav ul li a.dropbtn {
  font-size: 13px !important;
  cursor: pointer !important;
  padding: 0 !important;
  color: var(--text-color) !important;
}

.topfinance-nav ul.dropdown-content li {
  margin: 0 !important;
}

.topfinance-nav ul.dropdown-content li a {
  font-size: 12px;
  padding: 10px 15px !important;
}

.risection h4 {
  font-size: 16px;
  margin-bottom: 20px;
}

.checkdiv {
  margin-right: 15px;
}

.checkdiv input[type="checkbox"] {
  width: 15px;
  height: 15px;
  margin: 0;
  margin-right: 6px;
}

/* style 13-05-2024 End */

/* bd style 14-05-24 Start */
.expirevault {
  position: relative;
  background: #ffd1bc;
}

.expiredvault {
  position: relative;
  background: #e6e5e4;
}

@-webkit-keyframes pulse {
  0% {
    background-color: #f06a2f;
  }

  100% {
    background-color: #f8ac60;
  }
}

@keyframes pulse {
  0% {
    background-color: #f06a2f;
  }

  100% {
    background-color: #f8ac60;
  }
}

.expirevault .expiretxt {
  position: absolute;
  bottom: 10px;
  left: 0;
  background: #ff6b00;
  padding: 5px 10px;
  font-size: 10px;
  color: #fff;
  width: 100%;
  -webkit-animation: pulse 400ms infinite alternate;
  animation: pulse 400ms infinite alternate;
}

.expiredvault .expired {
  position: absolute;
  bottom: 10px;
  left: 0;
  background: #ada8a4;
  padding: 5px 10px;
  font-size: 10px;
  color: #fff;
  width: 100%;
}

/* .blinktext{
    background: #ff6b00;
    -webkit-animation: pulse 400ms infinite alternate;
    animation: pulse 400ms infinite alternate;
} */
/* / Invite / */

.invitenav img {
  width: 120px;
}

.invitenav {
  background: #0e1f33;
}

.invitenav figure {
  display: block;
}

.invitestep .stepnav .stepnavlink .stround {
  border-color: #6d9fd8;
}

/* / Invite End / */

/* bd style 14-05-24 end */
/* / 16.05.24 / bd style */

.pophover i {
  color: #007aff;
  margin-left: 5px;
  font-size: 14px;
}

.pophover {
  cursor: pointer;
}

.tooltipbd2 {
  top: inherit;
  bottom: -55px;
  background: #1e2538;
  color: #fff;
  font-weight: 400;
  left: 50%;
  transform: translateX(-50%) scale(0);
  z-index: 1;
}

.tooltipbd2::after {
  bottom: 0;
  top: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #1e2538;
  border-top: 0;
}

.pophover:hover .tooltipbd2 {
  opacity: 1;
  transform: translateX(-50%) scale(1);
  -webkit-transform: translateX(-50%) scale(1);
  -o-transform: translateX(-50%) scale(1);
  -ms-transform: translateX(-50%) scale(1);
  -moz-transform: translateX(-50%) scale(1);
}

.toerr em {
  font-style: normal;
  color: #b4b4b5;
  font-size: 10px;
  line-height: 10px;
}

.toerr {
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 12px;
  line-height: 12px;
}

.vaeditbtn1 {
  top: auto;
  text-align: center;
}

.vaeditbtn1 .vadropdowncs1 {
  width: auto;
  z-index: 1;
  top: 34px;
  right: 8px;
}

/* / Announcements / */
.announbox {
  width: 50%;
}

.announboxx h2 {
  color: #545454;
  font-size: 16px;
  line-height: 26px !important;
  margin-bottom: 6px;
}

.anndatetine i {
  color: #5b5e68;
  margin-right: 10px;
  font-size: 13px;
}

.anndatetine span {
  color: #8a8e9c;
  font-size: 13px;
  line-height: 18px;
  font-weight: 300;
}

.innerannounbox {
  border-top: 1px solid #dee2e6;
  padding: 15px 0 15px 40px;
  margin-top: 0px;
}

.annucommentbox {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 18px;
}

.annucommentbox input.form-control {
  border: 0;
  background: transparent;
}
.annucommentbox .btn img {
  width: 32px;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: var(--bs-btn-active-bg);
  border-color: transparent;
}

.form-control:focus {
  box-shadow: none;
}

.announbox + .announbox {
  border-top: 2px solid #cecece;
  padding-top: 20px;
  margin-top: 20px;
}

.skeleton {
  background-color: #e2e5e7;
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
  font-size: 0;
  display: block;
  height: 15px;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

/* / Pay Slip / */

.passec {
  columns: 3;
}

.passec li label input {
  height: auto;
}

.passec li label span {
  font-weight: 300;
}

/* bd style 16-05-24 */

/* sm style 14-05-2024 Start */
.profile-details .card-profile-img {
  width: 100%;
  height: 100%;
}

.error-element {
  font-size: 12px;
  color: red;
  margin-bottom: 0;
}

.form-switch input[type="checkbox"] {
  height: 18px !important;
  width: 40px;
}

.dropdown-content {
  z-index: 9 !important;
}

.addrowmain {
  display: flex;
  align-items: center;
}

label.radio-inline.addition_deduct {
  padding: 8px 18px;
  border: solid 1px #0d6efd;
  border-radius: 4px;
  color: #000;
  font-size: 13px;
}

ul.pagination li button {
  font-size: 13px !important;
  border-radius: 0 !important;
}

/* style 14-05-2024 End */

/* sm style 15-04-24 */

.ngx-dropdown-container .ngx-dropdown-button {
  border-color: #dee2e6 !important;
}

.btn-border img {
  height: 15px;
  margin-right: 4px;
}

.btn-border:hover img {
  filter: brightness(0%) invert(1);
}

.ngx-dropdown-container .ngx-dropdown-list-container {
  max-height: 200px;
}

.ngx-dropdown-container .ngx-dropdown-list-container::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.borderstyle .input-group-text {
  height: 40px;
  border-radius: 4px 0 0 4px;
  font-size: 13px;
}

.ngx-dropdown-container .ngx-dropdown-button .display-text {
  overflow: hidden;
}

.employee-table th,
.employee-table td {
  white-space: nowrap;
  padding: 15px !important;
  height: auto;
  font-size: 13px;
  vertical-align: middle;
}

.table.employee-table tr:first-child td:first-child {
  background: #fff;
}

.employee-table th {
  color: #4e525e;
  font-weight: 600;
}

.employee-table th input[type="checkbox"],
.employee-table td input[type="checkbox"] {
  margin-right: 10px;
}

.custom-pagination {
  padding: 15px;
  background-color: var(--white);
}

table {
  margin-bottom: 0 !important;
}

.employee-table.table tr:first-child td {
  height: auto;
  font-weight: 400;
  color: #8a8e9c;
}

/* style 15-04-24 End sm*/

/* 16-05-2024 start MD */
.bggreen {
  border: solid 1px #60b057 !important;
  background: #f0f7ef !important;
  color: #60b057 !important;
}

/* .pr-payout .ngx-dropdown-button{
    max-width: 200px;
} */
/* 16-05-2024 start MD */

/* 16-05-24 Start */
.mat-mdc-dialog-container .mdc-dialog__surface {
  box-shadow: unset !important;
}

.cdk-overlay-pane {
  overflow-y: auto;
  height: auto !important;
  /* padding: 30px 0;
    width: 100%; */
  box-shadow: 0px 0px 30px #0000003b;
}

.modal-text h3 {
  font-size: 15px;
  color: var(--text-color);
}

button:focus:not(:focus-visible) {
  box-shadow: none;
}

.modal-body h6.title {
  margin-bottom: 0;
  border: solid 1px #dee2e6;
  border-bottom: 0;
  padding: 12px 15px;
}

.modal-body .table tr:first-child td {
  height: auto;
}

.modal-body .table tr td {
  height: auto;
}

.modal-body .templete-item {
  margin-bottom: 15px;
}

th.text-uppercase.static-text.text-center {
  font-size: 13px;
  background: #e0e9fb;
  color: #4e525e;
}

.nav-item {
  margin-bottom: 5px;
}

.nav-item .nav-link {
  padding: 10px 12px;
}

.earningmain h3 {
  font-size: 15px;
}

.earningmain .input-group .input-group-text {
  border-radius: 0 4px 4px 0;
  height: 40px;
  font-size: 12px;
}

.earningmain h5 {
  font-size: 13px;
  margin-bottom: 14px;
}

.earningmain h5 span {
  min-width: 200px;
  display: inline-block;
  font-weight: 600;
}

.earningmain h5 b {
  font-weight: 500;
}

.modal-body img {
  width: 100%;
}

.card-header.h-auto.pt-3 {
  display: block;
}

/* 16-05-24 End */

/* 17-05-24 Start */

.topfilter ngx-select-dropdown {
  width: 200px;
}

.border-bottom {
  border-color: #efefef !important;
}

.green_action {
  border: 1px solid #60b057 !important;
  color: #60b057 !important;
  background: #f0f7ef !important;
  padding: 4px 10px;
  border-radius: 2px;
  text-transform: capitalize;
}

.yellow_action {
  border: 1px solid #ffac10 !important;
  color: #ffac10 !important;
  background: #ffa65325;
  padding: 4px 10px;
  border-radius: 2px;
  text-transform: capitalize;
}

/* 17.05.2024 start MD  */
.tbl-view.form-group label {
  position: static;
}

.c-table.border {
  border-radius: 4px;
}

.c-table.border .c-table-td {
  white-space: nowrap;
  height: 40px;
  vertical-align: middle;
  padding: 6px 15px;
  font-size: 12px;
  text-align: center;
  color: #8a8e9c;
}

.c-table-td input {
  margin-top: -10px;
}

.c-table.border h6,
.select-chbx {
  font-size: 12px;
  color: #4e525e;
  font-weight: 600 !important;
}

#settingsTemplateForm h5 {
  color: var(--text-color);
  font-size: 12px;
  font-weight: 600 !important;
  margin-top: 8px;
}

/* .ngx-dropdown-container .ngx-dropdown-list-container{
    top: unset !important;
} */
/* 17.05.2024 end MD  */

/* 20-05-2024 start MD  */
.cr-template-list {
  padding: 2px 0 !important;
  font-size: 14px;
}

#staff-form .radiolabel {
  flex: auto;
}

.estd-fm label {
  top: -12px;
}

.modal-text h3 .mat-dialog-title {
  font-size: 15px;
  font-weight: 500;
}

.estd-fm .form-check input {
  margin-top: 8px;
}

.mat-mdc-menu-item {
  min-height: 40px !important;
}

.mat-mdc-menu-item span {
  font-size: 14px !important;
}

/* 21-05-2024 */

.canversmain canvas {
  width: 100% !important;
}

@media only screen and (max-width: 1600px) {
  .vaultbox {
    margin: 0.8%;
    flex: 0 0 15%;
    max-width: 15%;
  }

  .topfilter ngx-select-dropdown {
    width: 180px;
  }

  label.ms_container.w-auto.h-auto .checkmark {
    top: 3px;
  }
}

@media only screen and (max-width: 991px) {
  .leftdeshbord {
    flex-wrap: wrap !important;
  }

  .height100 {
    height: auto;
  }

  .smhover_table .headcol {
    min-width: 220px;
  }

  .ms-auto.customleft {
    margin-left: unset !important;
  }

  .ms-auto.customleft .ms-3:first-child {
    margin-left: 0 !important;
  }
}

.button-solide span {
  transition: unset;
}

.pagenationtitle {
  font-size: 13px;
}

.ms_container {
  width: auto;
  display: flex;
}

.table_main {
  margin-left: 0;
}

.thead-colored.thead-info {
  vertical-align: middle;
}

/* madhu responsive css */
@media only screen and (max-width: 1600px) {
  /* / Header  / */
  .topfinance-nav ul li,
  .topfinance-nav ul li a,
  .topfinance-nav ul li a.dropbtn {
    font-size: 11px !important;
  }

  .topfinance-nav ul li.dropdown .dropdown-content {
    left: 50%;
    transform: translateX(-50%);
  }

  /* / Common CSS  / */
  .ngx-dropdown-container .ngx-dropdown-button,
  .form-control,
  input[type="text"],
  input[type="number"],
  textarea,
  .cs_select,
  .button-solide,
  .borderstyle .input-group-text {
    height: 42px !important;
    font-size: 11px;
  }

  .form-check-input {
    height: 1em !important;
  }

  .table tr td {
    height: 46px;
    font-size: 11px;
  }

  ul.pagination li button {
    font-size: 11px !important;
  }

  .pagenationtitle {
    font-size: 11px;
  }

  .buttonstyle {
    padding: 8px 12px !important;
  }

  /* Settings */
  .bd_settingstep {
    max-width: 80%;
  }

  .bd_settingstep .stepnav .stepnavlink a i {
    font-size: 12px;
  }

  .partnerlist .partnerbox {
    flex: 0 0 18%;
    max-width: 18%;
  }

  .card-header .d-flex .form-label {
    color: #6f7880 !important;
    padding: 0 5px;
    font-size: 12px;
  }

  .radio_container span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1199px) {
  /* Header */
  .app-header {
    height: 50px;
  }

  .vaultbox h5 {
    font-size: 13px;
  }

  /* .topfinance-nav ul li {
        margin: 0 20px 0 0;
    } */

  /* Common CSS */
  .graybtn {
    font-size: 10px;
    letter-spacing: 0.4px;
  }

  .check-btn {
    font-size: 11px;
  }

  .form-group label {
    font-size: 11px;
  }

  /*Settings */
  .bd_settingstep {
    max-width: 100%;
    margin-bottom: 15px;
  }

  .partnerlist .partnerbox {
    flex: 0 0 24%;
    max-width: 23%;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .partnerimg {
    width: 75px;
    height: 75px;
    margin-bottom: 12px;
    margin-top: -54px;
  }

  .combranchbox {
    padding-left: 15px;
  }

  .combranchbox ul li span,
  .combranchbox ul li strong {
    font-size: 13px;
    width: 100%;
  }

  .employ_name ul li {
    font-size: 13px;
  }

  .employ_name ul li i {
    font-size: 12px;
  }
}

@media only screen and (max-width: 991px) {
  .vaultbox {
    margin: 1%;
    flex: 0 0 23%;
    max-width: 23%;
  }

  .employ_name {
    max-width: 350px;
  }

  .announbox {
    width: 100%;
    max-width: 800px;
  }

  .serch-main {
    width: 200px;
  }

  /* Side Panel */
  .app-sidebar__header,
  .app-sidebar {
    width: 140px;
  }

  .app-content {
    padding-inline-start: 140px;
  }

  .main-sidemenu ul li span {
    padding: 10px 6px;
  }

  .main-sidemenu ul li span img {
    margin: 0 auto 6px auto;
  }

  .main-sidemenu ul li span h4 {
    font-size: 11px;
  }

  /* Header */
  .app-header {
    height: auto;
  }

  .app-header ul {
    row-gap: 12px;
  }

  /* .topfinance-nav ul li, .topfinance-nav ul li a {
        font-size: 10px;
    } */

  /* Common CSS */
  .checkmark {
    height: 12px;
    width: 12px;
    padding: 2px;
    border-radius: 2px;
  }

  .ms_container {
    width: 12px;
    height: 12px;
  }

  .select-width .ngx-dropdown-button {
    width: 160px !important;
  }

  .check_box label input {
    margin-right: 5px !important;
  }

  /* Payroll  */
  #cdk-overlay-0,
  .cdk-overlay-pane {
    /* min-width: 60% !important;
        max-width: 80vw !important; */
  }

  .c-tr .row [class*="col-"] {
    max-width: 33%;
    flex: 0 0 33%;
  }

  .c-table.border .c-table-td {
    padding: 4px 10px;
  }

  .c-table label {
    font-size: 11px;
    font-weight: 500;
  }

  .ms_container .checkmark:after {
    left: 3px;
    top: -1px;
  }

  div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: 15px;
  }

  div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: 20px;
  }

  div:where(.swal2-container) button:where(.swal2-styled) {
    padding: 8px 12px;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: var(--bs-blue);
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
    background-color: #c2c2c2;
  }

  /* .modal-text h3 {
        font-size: 13px;
    } */

  .ngx-dropdown-container .ngx-dropdown-button,
  .form-control,
  input[type="text"],
  input[type="number"],
  textarea,
  .cs_select,
  .button-solide {
    height: 34px !important;
  }

  .buttonstyle {
    padding: 5px 9px !important;
    font-size: 13px !important;
  }

  .table tr td {
    padding: 8px 12px !important;
  }

  /* Settings */
  .bd_settingstep .stepnav .stepnavlink .stround {
    width: 14px;
    height: 14px;
    border: 4px solid #6d9fd8;
  }

  .bd_settingstep .stepnav .stepnavlink.active .stround {
    width: 18px;
    height: 18px;
  }

  .partnerinfo ul li {
    font-size: 12px;
    line-height: 1.4;
  }

  .partnerlist .partnerbox {
    flex: 0 0 31%;
    max-width: 32%;
    padding: 15px 12px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .p-20 {
    padding: 15px;
  }

  .partnerimg {
    width: 70px;
    height: 70px;
    margin-bottom: 6px;
  }

  .partnerinfo .text-center p {
    font-size: 13px;
    line-height: 17px;
  }

  .usertext h3 {
    font-size: 15px;
  }

  .combranchbox ul li span,
  .combranchbox ul li strong {
    font-size: 12px;
  }
}

/* Madhu Responsive Css End */

/* 24.05.2024  */
.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: #fff !important;
  border-color: #d5d9e2 !important;
  opacity: 1;
  background-color: #d5d9e2 !important;
}

.btn.btn-icon.disabled .bi-caret-right-fill,
.btn.btn-icon:disabled .bi-caret-right-fill {
  border-left: solid 1px #fff;
}

.select-width .ngx-dropdown-button {
  width: 200px !important;
}

@media only screen and (max-width: 1600px) {
  .profile-describe [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }

  .profile-describe [class*="col-"] h6 {
    font-size: 12px;
  }

  .profile-describe span {
    font-size: 12px;
  }

  .profile-details {
    width: 100px;
    height: 100px;
  }

  /* .employ_name {
        min-width: 200px;
    } */

  /* Header  */
  /* .topfinance-nav ul li, .topfinance-nav ul li a {
        font-size: 11px;
    } */

  /* Common CSS  */
  .ngx-dropdown-container .ngx-dropdown-button,
  .form-control,
  input[type="text"],
  input[type="number"],
  textarea,
  .cs_select,
  .button-solide,
  .form-group label.checkbox-div,
  .h-40,
  .border-check {
    height: 36px !important;
    font-size: 11px;
  }

  .form-check-input {
    height: 1em !important;
  }

  .table tr td {
    height: 46px;
    font-size: 11px;
  }

  ul.pagination li button {
    font-size: 11px !important;
  }

  .pagenationtitle {
    font-size: 11px;
  }

  .buttonstyle {
    padding: 8px 12px !important;
  }
}

@media only screen and (max-width: 1199px) {
  /* Header  */
  .app-header {
    height: 50px;
  }

  /* Common CSS  */
  .graybtn {
    font-size: 10px;
    letter-spacing: 0.4px;
  }

  .check-btn {
    font-size: 11px;
  }

  .form-group label {
    font-size: 11px;
  }
}

@media only screen and (max-width: 991px) {
  .profile-describe [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }

  .profiledetails {
    flex-wrap: wrap;
  }

  /* Side Panel  */
  .app-sidebar__header,
  .app-sidebar {
    width: 140px;
  }

  .app-content {
    padding-inline-start: 140px;
  }

  .main-sidemenu ul li span {
    padding: 10px 6px;
  }

  .main-sidemenu ul li span img {
    margin: 0 auto 6px auto;
  }

  .main-sidemenu ul li span h4 {
    font-size: 11px;
  }

  /* Header  */
  .app-header {
    height: auto;
  }

  .app-header ul {
    row-gap: 12px;
  }

  /* .topfinance-nav ul li {
        margin: 0 30px 0 0;
    } */
  /* .topfinance-nav ul li, .topfinance-nav ul li a {
        font-size: 10px;
    } */

  /* Common CSS  */
  .checkmark {
    height: 12px;
    width: 12px;
    padding: 2px;
    border-radius: 2px;
  }

  .ms_container {
    width: 12px;
    height: 12px;
  }

  /* Payroll  */
  /* #cdk-overlay-0, .cdk-overlay-pane{
        min-width: 72% !important;
    } */
  .c-tr .row [class*="col-"] {
    max-width: 33%;
    flex: 0 0 33%;
  }

  .c-table.border .c-table-td {
    padding: 4px 10px;
  }

  .c-table label {
    font-size: 11px;
    font-weight: 500;
  }

  .ms_container .checkmark:after {
    left: 3px;
    top: -1px;
  }

  div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: 15px;
  }

  div:where(.swal2-container) h2:where(.swal2-title) {
    font-size: 20px;
  }

  div:where(.swal2-container) button:where(.swal2-styled) {
    padding: 8px 12px;
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: var(--bs-blue);
  }

  div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
    background-color: #c2c2c2;
  }
}

@media only screen and (max-width: 768px) {
}

.edijtlast {
  position: absolute;
  right: 0;
  width: 65px !important;
  text-align: center !important;
}

label b {
  font-weight: 500 !important;
}

.annutxt {
  font-size: 13px;
  color: #737477;
  margin-bottom: 10px;
}

.annutxt p {
  margin-bottom: 8px;
}

.gaprow {
  row-gap: 15px;
}

/* sm 28 */

textarea.form-control {
  padding: 9px 10px !important;
  /* height: auto !important; */
}

.sm-custombar ngx-select-dropdown {
  width: 170px;
}

ngx-select-dropdown {
  border: 0 !important;
  padding: 0 !important;
}

ngx-select-dropdown.p-1 {
  padding: 0 !important;
}

.stable.c-table label input {
  top: 0;
}

/* md style */

.select-width .ngx-dropdown-button {
  width: 160px !important;
}

.check_box label input {
  margin-right: 5px !important;
}

/* .modal-text h3 {
    font-size: 13px;
} */

.buttonstyle {
  padding: 5px 9px !important;
  font-size: 13px !important;
}

.table tr td {
  padding: 8px 12px !important;
}

/* md style */

.modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

/* width */
.modal-body::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.check-div {
  font-size: 13px;
}

.h-40 {
  height: 40px;
}

.scheck input[type="checkbox"] {
  top: 3px;
}

.smeditor.NgxEditor__Wrapper {
  border: 1px solid rgb(222 226 230);
}

.smeditor .NgxEditor__Content {
  height: 200px;
  overflow-y: auto;
}

.translatedrop .form-select.cs_select.cs_dark {
  margin-left: 0 !important;
  width: 100%;
  height: 55px !important;
}

.customdesign {
  font-size: 13px;
}

.footer_absolute {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

label.ms_container.w-auto.h-auto .checkmark {
  top: 5px;
}

.modal-body p {
  font-size: 13px;
  margin-bottom: 10px;
}

.mat-mdc-menu-content {
  padding: 0px 0 !important;
}

.mat-mdc-menu-content button span {
  font-size: 13px !important;
}

.custominput.form-check .form-check-input {
  position: relative;
  top: 5px;
}

.mdc-dialog .mdc-dialog__content h6 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.card-header ul li {
  margin-bottom: 10px;
}

.announcements_div h5 {
  font-size: 18px;
}

.annucom {
  margin-bottom: 15px;
}

.announcements_div button.btn.py-1 img {
  height: 35px;
}

.table tr td .form-control,
.table tr td .cs_select {
  min-width: 150px;
}

input[type="checkbox"].me-1 {
  margin-left: 6px !important;
}

.btn:disabled img {
  filter: brightness(0) invert(1);
}

/* sm design 03-06-0204 */

.orangtext {
  color: #ffac10;
}

.mploydtls {
  background: #f5f6fa;
  position: relative;
  padding: 20px 30px;
}

.mploydtls::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 2px;
  border: solid 1px var(--white);
}

.mploydtls::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 2px;
  border: solid 1px var(--white);
}

.mploydtls strong {
  font-size: 18px;
  font-weight: 600;
  margin-left: 15px;
}

.empsummary {
  padding: 20px 30px;
}

.empsummary h3 {
  color: #60b057;
  font-size: 14px;
  margin-bottom: 20px;
}

.empsummary strong {
  padding-left: 15px;
}

.totalctc h5 {
  color: #3d3d3d;
  font-size: 16px;
}

.totalctc h2 {
  color: #ffac10;
  font-weight: 700;
}

.totalctc h6 {
  color: #3d3d3d;
  font-size: 13px;
}

.gross-deduct {
  padding: 15px 30px;
  background: #f5f6fa;
  margin: 10px 0;
  position: relative;
}

.gross-deduct::before {
  content: "";
  position: absolute;
  left: 0;
  top: -5px;
  width: 100%;
  height: 1px;
  border-top: 1px dashed #3d3d3d;
}

.gross-deduct::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 1px;
  border-top: 1px dashed #3d3d3d;
}

.gross-deduct strong {
  color: var(--color-green);
  padding-left: 10px;
}

.borderafright {
  border-right: solid 1px #f5f6fa;
}

.greencolor-text {
  color: var(--color-green);
}

/* sm design 03-06-0204 End */

/* sm design 04-06-0204 Start */
.hadtitle {
  position: relative;
  padding-right: 32px;
}

.hadtitle h3 {
  margin-bottom: 0;
  position: relative;
  font-size: 16px;
  padding-right: 52px;
}

.hadtitle h3 .new {
  position: absolute;
  background: #fc6860;
  padding: 2px 8px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  border-radius: 0 8px 0 0;
  right: 0;
}

.hadtitle h5 {
  margin-bottom: 0;
  font-size: 13px;
}

.hadtitle h5 span {
  color: #007aff;
}

.clockimg {
  position: absolute;
  top: 50%;
  background-color: #007aff;
  right: -22px;
  transform: translateY(-50%);
  padding: 8px 12px;
  border-radius: 8px 0 0 8px;
  font-size: 18px;
  color: #fff;
}

.clockimg::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -6px;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-left: 7px solid #085fbf;
  border-bottom: 6px solid transparent;
  border-right: 0px solid #085fbf;
}

.swichdiv {
  color: #6e6f74;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.swichangmain {
  display: flex;
  align-items: center;
}

.swichangmain h4 {
  margin-right: 50px;
  font-size: 13px;
  color: #6e6f74;
  margin-bottom: 0;
}

.form-group p {
  font-size: 13px;
  color: #6f7880;
}

.holiday_rules h3,
.holiday_setup h3 {
  font-size: 14px;
}

hr {
  border-color: #cdcdcd;
}

.right-bar {
  padding-left: 15px;
}

.smbtn {
  min-width: auto !important;
}

.holiday_select {
  border: solid 1px #dee2e6;
  border-radius: 6px;
  padding: 10px;
  min-height: 100px;
  /* overflow-x: auto; */
}

.foledayrow {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-bottom: 8px;
}

/* width */
.foledayrow::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.foledayrow::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dbdadc;
  border-radius: 10px;
}

/* Handle */
.foledayrow::-webkit-scrollbar-thumb {
  background: #796391;
  border-radius: 10px;
}

/* Handle on hover */
.foledayrow::-webkit-scrollbar-thumb:hover {
  background: #796391;
}

.radio-group label.label {
  color: #6f7880 !important;
  position: absolute;
  top: -8px;
  left: 12px;
  z-index: 1;
  background: #fff;
  padding: 0 5px;
  font-size: 12px;
  margin-bottom: 6px;
  color: #8a8e9c;
  border-radius: 4px;
  line-height: normal;
}

/* redio holeday */

/* The container */
.radiomain {
  display: block;
  position: relative;
  padding-left: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

/* Hide the browser's default radio button */
.radiomain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radiomain .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #eee;
  border-radius: 0%;
  padding: 0;
  border: solid 2px #ffffff;
}

/* On mouse-over, add a grey background color */
.radiomain:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.radiomain input:checked ~ .checkmark {
  border: solid 1px #2196f3;
}

.radiomain {
  transition: 0.5s;
  border: 0;
}

.radiomain:hover {
  transform: scale(1.3);
  z-index: 9;
  border: 0;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
/* .radiomain .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
     */
/* Show the indicator (dot/circle) when checked */
.radiomain input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
/* .radiomain .checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    } */
.radiomain .checkmark img {
  width: 100%;
  height: 100%;
}

/* redio holeday */

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.grid-item {
  background-color: #eee;
  border: 1px solid #ccc;
  padding: 20px;
  font-size: 18px;
  text-align: center;
}

.four_div {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 80px;
}

.four_div .radiomain {
  flex: 0 0 50%;
  max-width: 50%;
}

.border-dash {
  border-style: dashed;
  border-color: #000000;
  margin: 30px 0 40px 0;
}

.text-success {
  color: #60b057 !important;
}

.tooltipbd2.smtooltip::after {
  border-bottom: 10px solid #ffffff;
}

.tooltipbd2.smtooltip {
  bottom: -42px;
}

/* sm design 04-06-0204 end */

/* sm design 04-06-0204 Start */
.hadtitle {
  position: relative;
  padding-right: 32px;
}

.hadtitle h3 {
  margin-bottom: 0;
  position: relative;
  font-size: 16px;
  padding-right: 52px;
}

.hadtitle h3 .new {
  /* position: absolute; */
  background: #fc6860;
  padding: 2px 8px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  border-radius: 0 8px 0 0;
  margin-left: 15px;
}

.hadtitle h3 .drafting {
  /* position: absolute; */
  background: #ffac10;
  padding: 2px 8px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  border-radius: 0 8px 0 0;
  margin-left: 15px;
}

.hadtitle h5 {
  margin-bottom: 0;
  font-size: 13px;
}

.hadtitle h5 span {
  color: #007aff;
}

.clockimg {
  position: absolute;
  top: 50%;
  background-color: #007aff;
  right: -22px;
  transform: translateY(-50%);
  padding: 8px 12px;
  border-radius: 8px 0 0 8px;
  font-size: 18px;
  color: #fff;
}

.clockimg::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -6px;
  width: 0;
  height: 0;
  border-top: 0px solid transparent;
  border-left: 7px solid #085fbf;
  border-bottom: 6px solid transparent;
  border-right: 0px solid #085fbf;
}

.swichdiv {
  color: #6e6f74;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.swichangmain {
  display: flex;
  align-items: center;
}

.swichangmain h4 {
  margin-right: 50px;
  font-size: 13px;
  color: #6e6f74;
  margin-bottom: 0;
}

.form-group p {
  font-size: 13px;
  color: #6f7880;
}

.holiday_rules h3,
.holiday_setup h3 {
  font-size: 14px;
}

hr {
  border-color: #cdcdcd;
}

.right-bar {
  padding-left: 15px;
}

.smbtn {
  min-width: auto !important;
}

.holiday_select {
  border: solid 1px #dee2e6;
  border-radius: 6px;
  padding: 10px;
  min-height: 100px;
  /* overflow-x: auto; */
}

.foledayrow {
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding-bottom: 8px;
}

/* width */
.foledayrow::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
.foledayrow::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #dbdadc;
  border-radius: 10px;
}

/* Handle */
.foledayrow::-webkit-scrollbar-thumb {
  background: #796391;
  border-radius: 10px;
}

/* Handle on hover */
.foledayrow::-webkit-scrollbar-thumb:hover {
  background: #796391;
}

.radio-group label.label {
  color: #6f7880 !important;
  position: absolute;
  top: -8px;
  left: 12px;
  z-index: 1;
  background: #fff;
  padding: 0 5px;
  font-size: 12px;
  margin-bottom: 6px;
  color: #8a8e9c;
  border-radius: 4px;
  line-height: normal;
}

/* redio holeday */

/* The container */
.radiomain {
  display: block;
  position: relative;
  padding-left: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

/* Hide the browser's default radio button */
.radiomain input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radiomain .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #eee;
  border-radius: 0%;
  padding: 0;
  border: solid 2px #ffffff;
}

/* On mouse-over, add a grey background color */
.radiomain:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the radio button is checked, add a blue background */
.radiomain input:checked ~ .checkmark {
  border: solid 1px #2196f3;
}

.radiomain {
  transition: 0.5s;
  border: 0;
}

.radiomain:hover {
  transform: scale(1.3);
  z-index: 9;
  border: 0;
}
.hidden {
  /* display: none; */
  visibility: hidden;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
/* .radiomain .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
     */
/* Show the indicator (dot/circle) when checked */
.radiomain input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
/* .radiomain .checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    } */
.radiomain .checkmark img {
  width: 100%;
  height: 100%;
}

/* redio holeday */

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.grid-item {
  background-color: #eee;
  border: 1px solid #ccc;
  padding: 20px;
  font-size: 18px;
  text-align: center;
}

.four_div {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 80px;
}

.four_div .radiomain {
  flex: 0 0 50%;
  max-width: 50%;
}

.border-dash {
  border-style: dashed;
  border-color: #000000;
  margin: 30px 0 40px 0;
}

.text-success {
  color: #60b057 !important;
}

.tooltipbd2.smtooltip::after {
  border-bottom: 10px solid #ffffff;
}

.tooltipbd2.smtooltip {
  bottom: -42px;
}

/* sm design 04-06-2024 end */

/* sm design 05-06-2024 Start*/

.branchholiday {
  background: #fff;
}

.headerleft {
  color: #fff;
  height: 60px;
  background: transparent linear-gradient(90deg, #1e2538 0%, #1e2026 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 2px 55px #14141412;
  border-radius: 4px 0px 0px 0px;
  padding: 0px 30px;
  line-height: 60px;
  font-size: 16px;
  text-align: center;
}

.headerright {
  background: #1e2538;
  color: #fff;
  justify-content: space-between;
  padding: 10px 25px;
  height: 60px;
  border-radius: 0 5px 0 0;
}

.headertitle h3 {
  font-size: 16px;
  margin-bottom: 3px;
}

.headertitle p {
  font-size: 13px;
  margin-bottom: 0;
  color: #8a8e9c;
}

.headerright .close {
  color: var(--white);
}

.positiontooltip {
  bottom: 141% !important;
  width: 174px;
  padding: 12px 12px;
}

.positiontooltip p {
  margin-bottom: 8px;
  color: #656667;
}

.positiontooltip p b {
  color: #000;
}

.positiontooltip p:last-child {
  margin-bottom: 0;
}

.positiontooltip.tooltipbd2::after {
  transform: translateX(-50%) rotate(180deg);
  top: 62px;
}

.branchholiday .serch-main .form-control {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-top: 0;
  height: 50px;
}

.left-navbar {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  height: calc(100% - 110px);
}

.left-navbar li {
  padding: 12px 15px;
  border-bottom: solid 1px #e7eaf1;
  position: relative;
  padding-right: 30px;
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.left-navbar li:hover {
  background-color: var(--color-blue);
  color: var(--white);
}

.left-navbar li:hover p {
  color: var(--white);
}

.left-navbar li i {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
}

.left-navbar li h3 {
  font-size: 14px;
  margin-bottom: 2px;
}

.left-navbar li p {
  margin-bottom: 0;
  color: #8a8e9c;
  font-size: 12px;
}

.shodoleft {
  box-shadow: 5px 0px 9px #1414140d;
}

.bodymainright {
  padding: 20px 25px;
}

.holedayname {
  display: flex;
}

.holedayname figure {
  width: 30px;
  margin-bottom: 0;
  margin-right: 10px;
}

.holedayname figcaption h3 {
  font-size: 14px;
  margin-bottom: 4px;
}

.holedayname figcaption h6 {
  font-size: 12px;
  color: #60b057;
  margin-bottom: 0;
}

.holedayname figcaption h6 span {
  font-size: 10px;
  background: #f0f7ef;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 4px;
}

.holedayyerlylist {
  border-top: solid 1px #e7eaf1;
  margin-top: -1px;
  padding-top: 15px;
}

.holedayyerlylist h3 {
  font-size: 16px;
  margin-bottom: 12px;
}

.list-main {
  display: flex;
  margin-bottom: 0px;
  border-bottom: 1px dashed #c8cbd1;
  padding: 15px 0;
  height: 100%;
}

.list-main figure {
  margin-bottom: 0;
  flex-shrink: 0;
  width: 35px;
}

.list-main figure img {
  width: 100%;
}

.list-main figcaption {
  padding-left: 6px;
  padding-right: 6px;
}

.list-main figcaption h5 {
  font-size: 13px;
  color: #6f7880;
  margin-bottom: 4px;
}

.list-main figcaption h5 span i {
  color: var(--color-blue);
  padding-left: 5px;
}

.list-main figcaption h6 {
  font-size: 12px;
  color: var(--color-blue);
  margin-bottom: 0;
}

.list-main {
  display: flex;
  margin-bottom: 0px;
  border-bottom: 1px dashed #c8cbd1;
  padding: 15px 0;
}

.remarkmodal {
  border: 1px solid #ebebeb;
  box-shadow: 0px 0px 27px #00000029;
  background-color: var(--white);
  padding: 25px;
}

.remarkmodal textarea {
  height: 100px;
}

.remarkmodal h3 {
  font-size: 16px;
  color: #687077;
  margin-bottom: 15px;
}

.holidaylistheader {
  background: #1e2538;
  color: #fff;
  padding: 14px 15px;
  display: flex;
  align-items: center;
}

.holidaylistheader h4 {
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: 400 !important;
}

.holidaylistheader h4 i {
  font-size: 12px;
}

.holidaylistheader p {
  font-size: 12px;
  margin-bottom: 0;
  color: #8a8e9c;
}

.holiday-listpopup {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  background: #fff;
  z-index: 99;
  box-shadow: -5px 1px 7px #00000005;
  width: 350px;
  height: 100%;
}

.holidaylistheader .close {
  color: var(--white);
}

.statusmain {
  padding: 15px 15px;
  border-bottom: solid 1px #e7eaf1;
}

.statusmain figure {
  margin-bottom: 0;
  width: 20px;
  margin-right: 5px;
  flex-shrink: 0;
}

.statusmain figure img {
  width: 100%;
}

.statusmain figcaption h4 {
  margin-bottom: 0;
  font-size: 16px;
  color: #4e525e;
}

.statusmain figcaption h4 span {
  color: #60b057;
  padding-left: 5px;
}

.statusmain h5 {
  font-size: 14px;
  color: #6f7880;
}

.statusmain h6 {
  font-size: 11px;
  margin-bottom: 0;
}

.statusmain h6 span {
  color: #60b057;
  background: #f0f7ef;
  padding: 3px 5px;
  border-radius: 4px;
}

.yearlist {
  padding: 15px 15px;
}

.yearlist h4 {
  font-size: 16px;
  color: #6f7880;
  margin-bottom: 5px;
}

.yearlist ul {
  margin: 0;
  padding: 0;
}

.yearlist ul li {
  display: flex;
  padding: 10px 0px;
  border-bottom: dashed 1px #c8cbd1;
}

.yearlist ul li figure {
  margin-bottom: 0;
}

.yearlist ul li figure {
  margin-bottom: 0;
  flex-shrink: 0;
  width: 35px;
  margin-right: 10px;
}

.yearlist ul li figure img {
  width: 100%;
}

.yearlist ul li figcaption h5 {
  color: #6f7880;
  font-size: 13px;
  margin-bottom: 3px;
}

.yearlist ul li figcaption h5 span {
  color: var(--color-blue);
}

.yearlist ul li figcaption p {
  margin-bottom: 0;
  font-size: 11px;
  color: #007aff;
}

.holedaylist-body {
  overflow-y: auto;
  height: calc(100% - 66px);
}

/* sm design 05-06-2024 End*/

.revisionmain .info-fluid {
  padding: 0px !important;
  margin-top: 15px;
}

.ngx-dropdown-container .ngx-dropdown-button:focus {
  border-color: var(--color-blue) !important;
}

.mat-content.mat-content-hide-toggle {
  margin: 0 !important;
}

.modal-body .ngx-dropdown-container .ngx-dropdown-list-container {
  max-height: 200px;
  position: fixed !important;
  top: unset !important;
  min-width: 300px !important;
  width: auto !important;
  text-align: left;
}

iframe body img {
  width: 100%;
}

.button-main {
  border-top: solid 1px #e9ecef;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 -15px;
  margin-bottom: -5px;
}

.row-heder {
  padding: 10px 10px;
  background: #ebebeb;
  font-weight: 600;
  font-size: 13px;
}

li.px-1.ng-star-inserted {
  font-weight: 500;
}

.carousel-indicators li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.shortcut-menu ul li a {
  cursor: pointer;
}

/* SM date 21-06-2024 start */

/* .holiday-listpopup{
    display: none;
} */

.numberofdate {
  font-size: 14px;
  font-weight: 600;
}

.date_had {
  color: #8a8e9c !important;
  font-weight: 500 !important;
}
.date_had span {
  color: #333;
  font-weight: 600;
}

.presentdiv {
  width: 50px;
  height: 50px;
  background: #f0f7ef;
  color: #60b057;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500 !important;
  padding: 2px 0px;
  text-transform: uppercase;
}

.table tr td.atandenchmain {
  padding: 4px !important;
  font-size: 12px;
}
.presentdiv:nth-child(2) {
  margin-top: 3px;
}

.wo-bg {
  background-color: #e6f2ff !important;
  color: #007aff;
}

.cl-bg {
  background-color: #f6efff !important;
  color: #9c5bff;
}
.absent-bg {
  background-color: #ffefef !important;
  color: #fc6860 !important;
}
.na-bg {
  background-color: #fff6e5 !important;
  color: #ffac10 !important;
}

/* SM date 21-06-2024 End */

/* 24-06-2024 sm */
.sm-ac .mat-content {
  padding-right: 45px !important;
}
.sm-ac mat-panel-description label.ms_container.w-auto.h-auto .checkmark {
  top: 2px;
  font-size: 13px;
}

.present.h-auto {
  line-height: 14px;
}
.table tr td.pcus_4 {
  padding: 4px 5px !important;
}

.sm-action .tooltipbd2 {
  bottom: unset;
  top: -45px;
}
.sm-action .tooltipbd2::after {
  top: unset;
  bottom: -10px;
  transform: rotate(180deg);
  left: 42%;
}

.table-big .table tr th {
  background-color: #f5f6fa;
}

.table-big .table tr td label input[type="checkbox"] {
  top: 2px;
  position: relative;
}

.gratuity-icon {
  padding: 2px 8px;
  font-size: 16px;
  border: solid 1px var(--color-green);
  background-color: #f0f7ef;
  color: var(--color-green) !important;
  display: inline-block !important;
  cursor: unset !important;
  border-radius: 4px;
}

/* design style 09-07-2024 */
input[type="checkbox"].me-1.ms-0 {
  margin-left: 0 !important;
}
.labletitle {
  color: #333;
  font-size: 13px;
  margin-bottom: 5px;
}
/* design style 09-07-2024 end*/

/* dashbord design start 22-07-2024*/

.annowithdate li {
  list-style: none;
  position: relative;
  margin-bottom: 15px;
  display: flex;
  line-height: 1.2;
  font-size: 13px;
  align-items: center;
}

.describe {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.annowithdate li span.dateann {
  margin-right: 10px;
  text-align: center;
  border-right: solid 2px #333;
  padding-right: 10px;
  line-height: 1.2;
  font-size: 13px;
  color: #888888;
  text-align: center;
  width: 50px;
  flex-shrink: 0;
}

.annowithdate li span b {
  display: block;
  color: #333;
  font-size: 15px;
}

.border-yello {
  border-color: #ffac10 !important;
}
.border-green {
  border-color: #60b057 !important;
}
.border-red {
  border-color: #fc6860 !important;
}

/* dashbord design start 22-07-2024*/

/* ATS Design Start */
.headstu {
  display: flex;
  align-items: center;
}
.hedtag {
  background: #fff3e9;
  color: #f28461;
  padding: 8px 12px;
  border-radius: 5px;
}

.border-btn {
  background: transparent !important;
  border: 1px solid #efeff3;
  color: #000 !important;
}
.border-btn i.bi-check {
  color: green;
  font-size: 14px;
  margin-right: 5px;
}

.border-btn i.bi-linkedin {
  margin-left: 8px;
  color: #117eb8;
  font-size: 15px;
}
.labelwarp label {
  position: relative;
  padding: 0;
  top: inherit;
  display: block;
  left: inherit;
  color: #000 !important;
  font-size: 14px;
}
.labelwarp textarea {
  height: 70px;
}
.labelwarp span.unit {
  color: #a8a8a8;
  font-size: 12px;
  position: absolute;
  top: 34px;
  right: 12px;
}
.slidepro {
  background: #ecf4fb;
  padding: 15px 20px;
}
.slidepro h2 {
  font-size: 20px;
  line-height: 26px !important;
  font-weight: 600 !important;
  margin-bottom: 20px;
}
.procelist {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 400px;
}
.procelist li {
  position: relative;
  padding: 0 0 0 36px;
}
.procelist li .grecheck {
  position: absolute;
  top: 0;
  left: 0;
  background: #c7f2e3;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  text-align: center;
  line-height: 25px;
  color: #029656;
}
.procelist li p {
  margin-bottom: 5px;
  color: #868f97;
}
.procelist li p span {
  color: #4182eb;
}
.procelist li + li {
  margin-top: 20px;
}
.slidepro .procelist li h2 {
  color: #1d232a;
  font-size: 18px !important;
  line-height: 24px !important;
  margin-bottom: 5px !important;
}
.procelist h5{
  font-size: 1.1rem;
  color: #005495;
  margin-bottom: 14px;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.procelist-item:not(:last-child){
  margin-bottom: 40px;
}
.imgtxt {
  display: flex;
  margin: 0;
  align-items: center;
}

.imgtxt img {
  width: 25px !important;
  height: 25px;
  object-fit: cover;
  border-radius: 50px;
  margin: 0 10px 0 0;
}
.requisitionslider .owl-theme .owl-nav [class*=owl-] {
  height: 40px;
  line-height: 24px;
  font-size: 32px;
  width: 48%;
  color: #005495;
  background: #e9ecef;
  transition: 0.3s ease-in-out;
}
.requisitionslider .owl-theme .owl-nav [class*=owl-]:hover{
  background-color: #005495;
  color: #fff;
}

.requisitionslider .owl-theme .owl-nav {
   margin: 0;
  display: block !important;
}
.greencheck {
  position: absolute;
  top: 4px;
  left: 0;
}
.requisitionslider input[type="checkbox"]{
  height: 25px !important;
  width: 25px;
  border-radius: 50%;
  appearance: none;
}
.requisitionslider input[type="checkbox"]:not(:checked){
  background-color: #ffa600;
}
.requisitionslider input[type="checkbox"]:checked{
  background-color: #c7f2e3;
  color: #000;
}
 /* Checkmark */
.checkbox-container{
  position: relative;
}
.checkbox-container::after {
  content: "";
  position: absolute;
  display: none;
  left: -15px;
  top: -5px;
  width: 6px;
  height: 11px;
  border: solid #029656;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
input:checked~.checkbox-container::after {
  display: block;
}
input:checked~.checkbox-container::before {
  display: none;
}
.checkbox-container::before {
  content: "!";
  position: absolute;
  left: -14px;
  top: -12px;
  color: #fff;
  font-size: 18px;
}

.bd_clopas .mat-expansion-panel:not([class*="mat-elevation-z"]) {
  border: 0;
}
.bd_clopas .mat-expansion-panel-header {
  height: auto;
}
.bd_clopas p {
  margin: 0;
}
.bd_clopas .detailstitle div[class^="col-"] {
  display: initial;
  color: #000;
  border-right: 0;
}
.bd_clopas .linke i {
  color: #007aff;
  margin: 0 10px 0 0;
}
.bd_clopas
  .mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
  background: transparent;
}
.showcount .custom-select {
  height: 38px;
  padding: 0 15px;
  margin: 0 6px;
  border-radius: 5px;
  border-color: #dee2e6;
}

.comple,
.onbor,
.sefbg {
  position: absolute;
  top: -12px;
  background: #ecf4fb;
  color: #4182eb;
  padding: 2px 6px;
  left: 30px;
  border-radius: 5px;
  font-size: 12px;
}

.onbor {
  left: 120px;
  background: #d5f8e9;
  color: #37b57f;
}
.sefbg {
  background: #fff3e9;
  color: #f27f5b;
}
.requisitionslider input[type="radio"] {
  position: absolute;
  right: 20px;
  top: 20px;
}
.requisitionslider label {
  border: 1px solid transparent;
  transition: 0.4s ease-in-out;
}
.requisitionslider label:has(input[type="radio"]:checked) {
  border-color: #007aff;
}
.slide-label {
  display: block;
  cursor: pointer;
}

/* Dropdown CSS  */
div.mm-dropdown {
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 3px;
}
div.mm-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 0;
}
div.mm-dropdown ul li,
div.mm-dropdown div.textfirst {
  padding: 0;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding: 5px 15px;
}
div.mm-dropdown div.textfirst img.down {
  float: right;
  margin-top: 5px;
}
div.mm-dropdown ul li:last-child {
  border-bottom: 0;
}
div.mm-dropdown ul li {
  display: none;
  padding-left: 25px;
}
div.mm-dropdown ul li.main {
  display: block;
}
div.mm-dropdown ul li img {
  width: 20px;
  height: 20px;
}

/* Interview Template  */
.inv-row > [class*="col-"]:nth-child(1) {
  padding-right: 40px;
  border-right: 1px dashed #b1b1b1;
}
.inv-row > [class*="col-"]:nth-child(2) {
  padding-left: 40px;
}
.inv-row .button-solide {
  padding: 0 10px;
}

/* Job Details Page  */
.design-tnav .tlink {
  background-color: #f5f7fb;
  color: #696d72;
  font-size: 14px;
  font-weight: 600;
  padding: 14px 20px;
  margin-right: 0;
  clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
  border: none;
}
.design-tnav .tlink + .tlink {
  padding-left: 26px;
  clip-path: polygon(92% 0, 100% 50%, 92% 100%, 0% 100%, 8% 50%, 0% 0%);
}
.design-tnav .tlink.active {
  background-color: #137cba;
  color: #fff;
}
.jobdtls-top figure {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 15px;
}
.jobdtls-top figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.jobdtls-top p {
  margin-bottom: 0;
  color: #696d72;
}
.jobdtls-top p span {
  margin-right: 15px;
  font-size: 13px;
}
.jobdtls-top h3 {
  font-weight: 500;
  font-size: 15px;
  color: #137cba;
  margin-bottom: 6px;
}
.jobdtls-top h4 {
  font-weight: 500;
  font-size: 13px;
  line-height: normal;
  color: #1f2124;
  margin-bottom: 0;
}
.subhead {
  font-weight: 600;
  font-size: 16px;
  color: #082637;
  margin-bottom: 15px;
}
.dtls-rpt {
  margin-bottom: 25px;
}
.iconhead img {
  margin-right: 10px;
}
.iconhead {
  font-weight: 600;
  font-size: 14px;
  color: #082637;
  margin-bottom: 12px;
}
.lightgbtn {
  color: #3b874d;
  background-color: #cfeed6;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 15px;
  font-size: 14px;
}
.lightgbtn i {
  font-size: 15px;
  margin-left: 8px;
}
.graybg {
  color: #082637;
  background-color: #f5f5f5;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 15px;
  font-size: 14px;
  font-weight: 500;
}
.tabbtn {
  top: -75px;
}

.scrollpopup h3.subhead {
  flex: 0 0 46%;
  font-size: 14px;
}
.scrollpopup .form-list:not(:last-of-type) {
  margin-bottom: 8px;
}
.bradius8 {
  border-radius: 8px;
}
.scrollpopup hr {
  margin: 0.5rem 0;
}
button.close i {
  font-size: 26px;
  color: #b6b6b6;
  font-weight: 800;
}

/* Dashboard  */
.pagetitle {
  color: #272727;
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
}
.filterbtn {
  background: #ffffff;
  border: 2px solid #f5f5f5 !important;
  border-radius: 8px;
  color: #272727;
  padding: 0 13px !important;
  height: 42px;
  display: flex !important;
  align-items: center;
  font-size: 14px;
}
.formselect {
  border: 1px solid #dcdcdc;
  height: 38px;
  padding: 0 35px 0 10px;
  border-radius: 6px;
  font-weight: 500;
  /* background-image: url(assets/images/down-arrow.png); */
  background-repeat: no-repeat;
  background-position: center right 10px;
  background-size: 14px;
  appearance: none;
  outline: none !important;
  color: #1f2124;
  font-size: 12px;
}
.cardbox,
.cardbox.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #f2f2f2;
  border: 1px solid #eeeff2;
  border-radius: 4px;
  height: 100%;
}
.icon {
  width: 45px;
  background: #f5f7fb;
  height: 45px;
  text-align: center;
  line-height: 43px;
  border-radius: 50px;
  color: #5bc1fd;
  flex-shrink: 0;
}
.icon svg {
  width: 25px;
  height: 25px;
}
.rgtcard p {
  color: #1f2124;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 0;
  font-weight: 500;
}
.rgtcard h2 {
  margin: 0;
  color: #137cba;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}
.headtable .heading {
  padding: 20px 10px;
  border-bottom: 1px solid #eeeff2;
}
.dashboard-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.cs_select {
  width: 152px;
}
.dashboard-table .table tr td {
  padding: 14px 12px !important;
}
/* ATS Design End */


/* Offer Letter start  */
.jobdata li:not(:last-child){
  margin-bottom: 8px;
}
.jobdata li strong{
  min-width: 200px;
  display: inline-block;
}
.empname{
  font-size: 14px;
  color: #082637;
  font-weight: 600;
}
.outborder{
  position: relative;
  border-left: 20px solid #ffac10;
  border-right: 4px solid #dedede;
}
.yelborder{
  padding: 60px;
}
.outborder::before{
  content: "";
  clip-path: polygon(0 0, 100% 12%, 100% 100%, 0% 100%);
  height: 60%;
  width: 40px;
  background: #ffac10;
  position: absolute;
  bottom: 0;
  left: -20px;
}
.outborder::after{
  content: "";
  clip-path: polygon(0% 8%, 100% 0%, 100% 100%, 0% 92%);
  height: 40%;
  width: 20px;
  background: #dedede;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -2px;
}

.letter-footer{
  background: #ffac1020;
  padding: 26px 64px;
}
.letter-footer li{
  position: relative;
  padding-left: 48px;
  flex: 0 0 32%;
}
.letter-footer li img{
  background-color: #ffac1020;
  height: 38px;
  width: 38px;
  padding: 6px;
  border: 1px solid #ffab0f;
  border-radius: 2px;
  filter: brightness(0) saturate(100%) invert(77%) sepia(31%) saturate(3816%) hue-rotate(350deg) brightness(101%) contrast(101%);
  position: absolute;
  top: 0;
  left: 0;
}
.letter-footer li p{
  font-size: 14px;
  font-weight: 600;
  color: #ffac10;
  margin-bottom: 0;
}
.letter-footer li span{
  font-weight: 500;
  font-size: 13px;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  height: 30px !important;
}
/* Offer Letter end  */




/* ATS Fixing  */
.job-view .dtls-rpt {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.job-view .iconhead{
  margin-bottom: 0;
}
.job-view .dtls-rpt div:first-of-type{
  flex: 0 0 30%;
}
.job-view .dtls-rpt .graybg{
  margin: 0; 
}
.job-form{
  background: #ecf4fb;
}
.job-form .subhead{
  background: #137cba;
  color: #fff;
  font-size: 20px;
  padding: 18px;
  text-align: center;
  border-bottom: 12px solid #fff;
}
.job-form form{
  padding: 32px;
}
.job-form .form-group {
  margin-bottom: 20px !important;
}
.job-form p{
  margin-bottom: 0;
}
.job-form label{
  font-size: 13px;
  background: transparent;
}

.requisitionslider span.checkbox-container {
  height: 25px !important;
  width: 25px;
  border-radius: 50%;
  appearance: none;
  display: block;
  line-height: 25px;
  text-align: center;
}

.requisitionslider .checkbox-container .bi {
  font-size: 18px;
  color: white;
}

.requisitionslider .checkbox-container.pe_check{
  background-color: #ffa600;
}
.requisitionslider .checkbox-container.gr_check{
  background-color: #c7f2e3;
}

.requisitionslider .checkbox-container.gr_check .bi {
  color: #029656;
}

.requisitionslider .checkbox-container.re_check{
  background-color: rgb(209, 47, 47);
}

.gr_num {
  background-color: #89b4f9;
  color: #fff;
}

.cr-rq-edit .requisitionslider .owl-theme .owl-nav {
  display: none !important;
}

.mat-tooltip {
  max-width: 500px !important; /* Change width as needed */
  white-space: normal !important; /* Allows text wrapping */
}
.my-custom-tooltip {
  max-width: unset !important;
}

.my-custom-tooltip .mdc-tooltip__surface {
  max-width: 260px;
}